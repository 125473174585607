// import Swiper from 'swiper/bundle';
import { gsap, Power2, ScrollTrigger } from 'gsap/all';

export default {
    init() {
        const swup = new Swup();

        // run once
        init();

    },
    finalize() {

        $('video').each((i, v) => { $(v).attr('data-keepplaying', ''); v.play(); });
        // $('#fullpage').fullpage();

        document.addEventListener('DOMContentLoaded', init);

        const swup = new Swup();

        swup.on('pageLoaded', init);

        $(document).ready(function (event) {

            if (navigator.userAgent.indexOf('Mac OS X') != -1) {
                $("body").addClass("mac");
            } else {
                $("body").addClass("windows");
            }


            (function () {
                swup.on('contentReplaced', getReplaceHead);
                function getReplaceHead() {
                    const swiper = new Swiper(".product-grid-slider", {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        breakpoints: {
                            320: {
                                slidesPerView: 1.5,
                                spaceBetween: 16,
                                centeredSlides: true,
                                loop: true,
                            },
                            480: {
                                slidesPerView: 1.5,
                                spaceBetween: 48,
                                centeredSlides: true,
                                loop: true,
                            },
                            768: {
                                slidesPerView: 2.3,
                                centeredSlides: true,
                                spaceBetween: 144,
                                loop: true,
                            },
                            1000: {
                                slidesPerView: 2.3,
                                centeredSlides: true,
                                spaceBetween: 280,
                                loop: true,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 32,
                                loop: false,
                            },
                        },
                        observer: true,
                        observeParents: true,
                        keyboard: { onlyInViewport: true },
                    });

                    // Product grid slide End //
                    const swiper12 = new Swiper(".BgPicTxtL_BgPicTxtR-slide", {
                        slidesPerView: 1,
                        allowTouchMove: true,
                        spaceBetween: 16,
                        navigation: {
                            nextEl: ".swiper-button-next2",
                            prevEl: ".swiper-button-prev2",
                        },
                        breakpoints: {
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                                loop: false,
                            },
                        },
                        keyboard: { onlyInViewport: true },
                    });

                    const timeline = new Swiper(".swiper-container--timeline", {
                        autoplay: false,
                        allowTouchMove: true,
                        slidesPerView: 1,
                        speed: 2000,
                        loop: false,
                        mousewheel: false,
                        grabCursor: false,
                        navigation: {
                            nextEl: ".swiper-button-next3",
                            prevEl: ".swiper-button-prev3"
                        },
                        on: {
                            init: function () {
                                $(".swiper-pagination-custom .swiper-pagination-switch").removeClass("active");
                                $(".swiper-pagination-custom .swiper-pagination-switch").eq(0).addClass("active");
                            },
                            slideChangeTransitionStart: function () {
                                $(".swiper-pagination-custom .swiper-pagination-switch").removeClass("active");
                                $(".swiper-pagination-custom .swiper-pagination-switch").eq(timeline.realIndex).addClass("active");
                            }
                        },
                        keyboard: { onlyInViewport: true },
                    });

                    $(".swiper-pagination-custom .swiper-pagination-switch").click(function () {
                        timeline.slideTo($(this).index());
                        $(".swiper-pagination-custom .swiper-pagination-switch").removeClass("active");
                        $(this).addClass("active");
                    });

                    // tabs start
                    $(document).ready(function () {
                        var $btns = $('#team_list > li > .tab').click(function () {
                            $('#members > div').hide();
                            $('#members > div').removeClass('team-fade');
                            $('#members > div').removeClass('team-out');
                            if (this.id == 'member1') {
                                $('#members > .member1').show();
                                $('#members > .member1').addClass('team-fade');
                                var $el = $('.' + this.id);
                                $('#members > div').not($el).addClass('team-out');
                            } else {
                                $('.' + this.id).show();
                                var $el = $('.' + this.id).addClass('team-fade');
                                $('#members > div').not($el).addClass('team-out');
                            }
                            $btns.removeClass('active');
                            $(this).addClass('active');
                        });
                    });

                    $(document).ready(function () {
                        var $cities = $('.cities-list > li > .tab').click(function () {

                            $('.contact-tabs-img > div').hide();
                            $('.contact-tabs-img > div').removeClass('bg-city-fade');
                            $('.contact-tabs-img > div').removeClass('bg-city-out');
                            if (this.id == 'city0') {
                                $('.contact-tabs-img > .city0').show();
                                $('.contact-tabs-img > .city0').addClass('bg-city-fade');
                                var $el23 = $('.contact-tabs-img > .' + this.id);
                                $('.contact-tabs-img > div').not($el23).addClass('bg-city-out');
                            } else {
                                $('.contact-tabs-img > .' + this.id).show();
                                var $el23 = $('.contact-tabs-img > .' + this.id).addClass('bg-city-fade');
                                $('.contact-tabs-img > div').not($el23).addClass('bg-city-out');
                            }

                            $('#cities > div').hide();
                            $('#cities > div').removeClass('city-fade');
                            $('#cities > div').removeClass('city-out');
                            if (this.id == 'city0') {
                                $('#cities > .city0').show();
                                $('#cities > .city0').addClass('city-fade');
                                var $el2 = $('#cities > .' + this.id);
                                $('#cities > div').not($el2).addClass('city-out');
                            } else {
                                $('#cities > .' + this.id).show();
                                var $el2 = $('#cities > .' + this.id).addClass('city-fade');
                                $('#cities > div').not($el2).addClass('city-out');
                            }
                            $cities.removeClass('active');
                            $(this).addClass('active');
                        });
                    });
                    $('.hamburger').click(function () {
                        $('.hamburger').toggleClass('active');
                        $('.main-nav').toggleClass('menu-reveal');
                        $('.aside-menu > ul').removeClass("active");
                        if ($(this).hasClass('active')) {
                            setTimeout(function () {
                                $('.logo').addClass('active');
                            }, 300);
                        } else {
                            setTimeout(function () {
                                $('.logo').removeClass('active');
                            }, 800);
                        }
                    });

                    $('.hamburger').on('click', function () {
                        var $el = $(this);
                        setTimeout(function () {
                            $el.toggleClass('ani-rotate');
                        }, 800);
                    });

                    $('#menu-main-menu li').click(function () {
                        // $(this).addClass("active");
                        if ($('#menu-main-menu li').hasClass('active')) {
                            $(this).removeClass('active');
                        } else {
                            $(this).addClass('active');
                        }
                        $('.aside-menu > ul').removeClass("active");
                        $(`.aside-menu ul.${$(this).attr("data-menu")}`).addClass("active");
                    });

                    $('.product-grid-slider .BgFillC_TxtC-content').hover(function () {
                        $('.details, .avatar').removeClass("active");
                        $(this).children('.details, .avatar').addClass("active");
                    });

                    $('.navMenu li').click(function () {
                        setTimeout(function () {
                            $('.aside-menu').addClass("active");
                        }, 0);
                        $('.mainNav-grid').addClass("active");
                    });
                
                    $('.navMenu li.backsub').click(function () {
                        setTimeout(function () {
                            $('.aside-menu').addClass("active");
                        }, 0);
                        $('.mainNav-grid').addClass("active");
                    });

                    $('.navMenu li.backsub').click(function () {
                        $('.aside-menu ul.about').addClass("active");
                    });

                    $('.backMain').click(function () {
                        $('.aside-menu').removeClass("active");
                    });

                    $('.navMenu li').click(function () {
                        $('.navMenu').addClass("menuClose");
                    });

                    $('.backMain').click(function () {
                        $('.navMenu').removeClass("menuClose");
                    });

                    $('.aside-menu ul li a').click(function () {
                        $('.aside-menu').removeClass('active');
                        $('.main-nav').removeClass('menu-reveal');
                        $('.navbar-icon').removeClass('active ani-rotate');
                    });

                    $('.aside-menu li a').on('click', function () {
                        $('.logo').removeClass('active');
                    });

                    $('.logo').on('click', function () {
                        $(this).removeClass('active');
                        $('.main-nav').removeClass('menu-reveal');
                        $('.hamburger').removeClass('active');
                    });
                    
                    $('.sr-logo a').on('click', function () {
                        $('.logo').removeClass('active');
                        $('.main-nav').removeClass('menu-reveal');
                        $('.hamburger').removeClass('active ani-rotate');
                    });

                    $('.column-slider .hyperlink').hover(function () {
                        $('.column-slider .swiper-slide:nth-child(2) div:nth-child(1)').toggleClass('scale');
                    }); 
                    
                    $(document).ready(function() {

                        setTimeout(function() {
                          $(".sunrays .title-logo").addClass("fadeInUp");
                        }, 20);
                      
                        setTimeout(function() {
                          $(".sunrays .content").addClass("fadeInUp");
                        }, 25);
                      
                        setTimeout(function() {
                          $(".sunrays .sunray-btn").addClass("fadeInUp");
                        }, 30);
                                           
                    });
                }
            })();
        });
    },
};

function init() {
    $('.product-grid-slider .BgFillC_TxtC-content').hover(function () {
        $('.details, .avatar').removeClass("active");
        $(this).children('.details, .avatar').addClass("active");
    });
}

// tabs start
$(document).ready(function () {

    var $btns = $('#team_list > li > .tab').click(function () {
        $('#members > div').hide();
        $('#members > div').removeClass('team-fade');
        $('#members > div').removeClass('team-out');
        if (this.id == 'member1') {
            $('#members > .member1').show();
            $('#members > .member1').addClass('team-fade');
            var $el = $('.' + this.id);
            $('#members > div').not($el).addClass('team-out');
        } else {
            $('.' + this.id).show();
            var $el = $('.' + this.id).addClass('team-fade');
            $('#members > div').not($el).addClass('team-out');
        }
        $btns.removeClass('active');
        $(this).addClass('active');
    });
});

$(document).ready(function () {
    var $cities = $('.cities-list > li > .tab').click(function () {

        $('.contact-tabs-img > div').hide();
        $('.contact-tabs-img > div').removeClass('bg-city-fade');
        $('.contact-tabs-img > div').removeClass('bg-city-out');
        if (this.id == 'city0') {
            $('.contact-tabs-img > .city0').show();
            $('.contact-tabs-img > .city0').addClass('bg-city-fade');
            var $el23 = $('.contact-tabs-img > .' + this.id);
            $('.contact-tabs-img > div').not($el23).addClass('bg-city-out');
        } else {
            $('.contact-tabs-img > .' + this.id).show();
            var $el23 = $('.contact-tabs-img > .' + this.id).addClass('bg-city-fade');
            $('.contact-tabs-img > div').not($el23).addClass('bg-city-out');
        }

        $('#cities > div').hide();
        $('#cities > div').removeClass('city-fade');
        $('#cities > div').removeClass('city-out');
        if (this.id == 'city0') {
            $('#cities > .city0').show();
            $('#cities > .city0').addClass('city-fade');
            var $el2 = $('#cities > .' + this.id);
            $('#cities > div').not($el2).addClass('city-out');
        } else {
            $('#cities > .' + this.id).show();
            var $el2 = $('#cities > .' + this.id).addClass('city-fade');
            $('#cities > div').not($el2).addClass('city-out');
        }
        $cities.removeClass('active');
        $(this).addClass('active');
    });
});

// Menu Start //
$(document).ready(function () {
    $('.hamburger').click(function () {
        $('.hamburger').toggleClass('active');
        $('.main-nav').toggleClass('menu-reveal');
        $('.aside-menu > ul').removeClass("active");
        if ($(this).hasClass('active')) {
            setTimeout(function () {
                $('.logo').addClass('active');
            }, 300);
        } else {
            setTimeout(function () {
                $('.logo').removeClass('active');
            }, 800);
        }
    });

    $('.hamburger').on('click', function () {
        var $el = $(this);
        setTimeout(function () {
            $el.toggleClass('ani-rotate');
        }, 800);
    });

    $('.aside-menu li a').on('click', function () {
        $('.logo').removeClass('active');
    });

    $('.logo').on('click', function () {
        $(this).removeClass('active');
        $('.main-nav').removeClass('menu-reveal');
        $('.hamburger').removeClass('active');
    });
    $('.sr-logo a').on('click', function () {
        $('.logo').removeClass('active');
        $('.main-nav').removeClass('menu-reveal');
        $('.hamburger').removeClass('active ani-rotate');
    });

    $('.navMenu li a').on('click', function () {
        $('#fullpage').removeClass('active');
    });
});
// Menu End //

// Product grid slider Start //
const swiper = new Swiper(".product-grid-slider", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1.5,
            spaceBetween: 16,
            centeredSlides: true,
            loop: true,
        },
        480: {
            slidesPerView: 1.5,
            spaceBetween: 48,
            centeredSlides: true,
            loop: true,
        },
        768: {
            slidesPerView: 2.3,
            centeredSlides: true,
            spaceBetween: 144,
            loop: true,
        },
        1000: {
            slidesPerView: 2.3,
            centeredSlides: true,
            spaceBetween: 280,
            loop: true,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 32,
        },
    },
    observer: true,
    observeParents: true,
    keyboard: { onlyInViewport: true },
});
// Product grid slide End //

// BgPicTxtL_BgPicTxtR-slide start
const swiper12 = new Swiper(".BgPicTxtL_BgPicTxtR-slide", {
    slidesPerView: 1,
    allowTouchMove: true,
    spaceBetween: 0,
    navigation: {
        nextEl: ".swiper-button-next2",
        prevEl: ".swiper-button-prev2",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 2,
            spaceBetween: 16,
            loop: false,
        },
    },
    keyboard: { onlyInViewport: true },
});
// BgPicTxtL_BgPicTxtR-slide end

// History swiper start
$(document).ready(function () {
    const timeline = new Swiper(".swiper-container--timeline", {
        autoHeight: false,
        allowTouchMove: true,
        slidesPerView: 1,
        autoplay: false,
        speed: 2000,
        loop: false,
        mousewheel: false,
        grabCursor: false,
        navigation: {
            nextEl: ".swiper-button-next3",
            prevEl: ".swiper-button-prev3"
        },
        on: {
            init: function () {
                $(".swiper-pagination-custom .swiper-pagination-switch").removeClass("active");
                $(".swiper-pagination-custom .swiper-pagination-switch").eq(0).addClass("active");
            },
            slideChangeTransitionStart: function () {
                $(".swiper-pagination-custom .swiper-pagination-switch").removeClass("active");
                $(".swiper-pagination-custom .swiper-pagination-switch").eq(timeline.realIndex).addClass("active");
            }
        },
        keyboard: { onlyInViewport: true },
    });
    $(".swiper-pagination-custom .swiper-pagination-switch").click(function () {
        timeline.slideTo($(this).index());
        $(".swiper-pagination-custom .swiper-pagination-switch").removeClass("active");
        $(this).addClass("active");
    });
});
// History swiper end


$(document).ready(function () {
    $('#menu-main-menu li').click(function () {
        if ($('#menu-main-menu li').hasClass('active')) {
            $('#menu-main-menu li').removeClass('active');
            $(this).addClass("active");
        } else {
            $(this).addClass("active");
        }
        $('.aside-menu > ul').removeClass("active");
        $(`.aside-menu ul.${$(this).attr("data-menu")}`).addClass("active");
    });

    $('.sub-menu li').click(function () {
        if ($('.sub-menu li').hasClass('active')) {
            $('.sub-menu li').removeClass('active');
            $(this).addClass("active");
        } else {
            $(this).addClass("active");
        }
    });

    $('.navMenu li').click(function () {
        setTimeout(function () {
            $('.aside-menu').addClass("active");
        }, 0);
        $('.mainNav-grid').addClass("active");
    });

    $('.navMenu li.backsub').click(function () {
        setTimeout(function () {
            $('.aside-menu').addClass("active");
        }, 0);
        $('.mainNav-grid').addClass("active");
    });

    $('.navMenu li.backsub').click(function () {
        $('.aside-menu ul.about').addClass("active");
    });

    $('.backMain').click(function () {
        $('.aside-menu').removeClass("active");
    });

    $('.navMenu li').click(function () {
        $('.navMenu').addClass("menuClose");
    });

    $('.backMain').click(function () {
        $('.navMenu').removeClass("menuClose");
    });
    $('.aside-menu ul li a').click(function () {
        $('.aside-menu').removeClass('active');
        $('.main-nav').removeClass('menu-reveal');
        $('.navbar-icon').removeClass('active ani-rotate');
        $('.navMenu').removeClass("menuClose");
    });
    $('.product-grid-slider .BgFillC_TxtC-content').hover(function () {
        $('.details, .avatar').removeClass("active");
        $(this).children('.details, .avatar').addClass("active");
    });
});

$(document).ready(function () {
    $("body").removeClass("fp-watermark");
    
    $('.column-slider .hyperlink').hover(function () {
        $('.column-slider .swiper-slide:nth-child(2) div:nth-child(1)').toggleClass('scale');
    });
});

$(document).ready(function () { $('p:empty').remove(); });

$("p").html(function (i, html) {
    return html.replace(/&nbsp;/g, '');
});

$(document).ready(function() {

    setTimeout(function() {
      $(".sunrays .title-logo").addClass("fadeInUp");
    }, 20);
  
    setTimeout(function() {
      $(".sunrays .content").addClass("fadeInUp");
    }, 25);
  
    setTimeout(function() {
      $(".sunrays .sunray-btn").addClass("fadeInUp");
    }, 30);

});
